import React from 'react'
import {Helmet} from 'react-helmet'
import socket from "../../../Socket";
import {BORDER_RADIUS, BRAND, COIN_TOKEN, formatAmount} from "../../../Helper";
import C from "../../../Constant";
import UserModal from "../../Components/User/Stat/Modal";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import {Avatar, Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

class TopUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            players: []
        }
    }

    componentDidMount() {
        socket.emit(C.TOP_WINNERS);
        socket.on(C.TOP_WINNERS, data => this.makeList(data));
    }

    makeList = (data) => {
        data = data.filter((item, pos, self) => self.findIndex(v => v.name === item.name) === pos);
        this.setState({loading: false, players: (data)})
    };

    render() {
        const {t} = this.props;

        const list = this.state.players.map((player, i) =>
            <Players key={i} medal={i + 1} player={player}/>
        );

        return (
            <>
                <Helmet>
                    <title>{BRAND} - Top Users</title>
                </Helmet>

                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer sx={{maxHeight: 211}}>
                        <Table stickyHeader size="small" aria-label="player list">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>Rank</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}}>User</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} align="right">Total Profit [KES]</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} align="left">&nbsp;&nbsp;Plays</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff7d'}} align="right">Medal&nbsp;&nbsp;&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </>
        );
    }
}

class Players extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {player, medal} = this.props;
        let num = medal;

        if (medal === 10) {
            medal = 1
        }

        let coin = COIN_TOKEN;
        let username = player.name;
        let avatar = player.avatar;
        let profit_high = formatAmount(parseFloat(player.profit_high[coin]).toFixed(2));
        let plays = player.plays;

        return (
            <>
                <TableRow key={num} sx={{backgroundColor: '#000000eb'}}>
                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} component="th" scope="row"><Avatar sx={{ width: 24, height: 24, bgcolor: 'rgb(44, 45, 48)' }}>{num}</Avatar></TableCell>
                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} component="th" scope="row">{username}</TableCell>
                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} align="right">{profit_high}</TableCell>
                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} align="left">
                        <div style={{borderRadius: BORDER_RADIUS, backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                            {plays}
                        </div>
                    </TableCell>
                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d'}} align="right">
                        <img className="rounded-circle thumb-xxs ffi" src={'/assets/images/badges/badge-' + medal + '.svg'}/>&nbsp;&nbsp;&nbsp;
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

export default TopUsers;